var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-5 px-2 flex flex-col gap-y-3"},[(_vm.user)?_c('div',{staticClass:"\n      bg-white\n      py-2\n      px-2\n      flex\n      items-center\n      rounded-cu\n      shadow\n      border border-solid border-gray-200\n    "},[_c('router-link',{staticClass:"text-lg text-gray-800 hover:underline font-EffraL",attrs:{"to":"/patients"}},[_vm._v("Patientèle")]),_c('i',{staticClass:"fas fa-angle-double-right fa-sm mx-2"}),_c('span',{staticClass:"text-lg text-gray-800 font-EffraR"},[_vm._v(_vm._s(((_vm.user.firstName) + " " + (_vm.user.lastName))))])],1):_vm._e(),_c('header',{staticClass:"border-2 border-solid border-gray-300 rounded-cu bg-white p-5"},[_c('UserInformation',{attrs:{"user":_vm.user}})],1),_c('nav',[_c('ul',{staticClass:"flex flex-col xl:flex-row"},[(_vm.authorized)?_c('li',{staticClass:"\n          py-3\n          px-5\n          font-EffraR\n          w-full\n          text-center\n          border-0 border-t border-r\n          xl:border-r-0 xl:border-b\n          border-l border-solid border-dokBlue-ultra\n          cursor-pointer\n          rounded-l-none\n          xl:rounded-l-lg\n        ",class:_vm.page !== 1
            ? 'bg-white text-dokBlue-ultra'
            : 'bg-dokBlue-ultra text-white',on:{"click":function($event){_vm.page = 1}}},[_vm._v(" Carnet de santé ")]):_vm._e(),_c('li',{staticClass:"\n          py-3\n          px-5\n          font-EffraR\n          w-full\n          text-center\n          border-0 border-t border-r\n          xl:border-r-0 xl:border-b\n          border-l border-solid border-dokBlue-ultra\n          cursor-pointer\n        ",class:_vm.page !== 2
            ? 'bg-white text-dokBlue-ultra'
            : 'bg-dokBlue-ultra text-white',on:{"click":function($event){_vm.page = 2}}},[_vm._v(" Consultations ")]),(_vm.authorized)?_c('li',{staticClass:"\n          py-3\n          px-5\n          font-EffraR\n          w-full\n          text-center\n          border-0 border-t border-r\n          xl:border-r-0 xl:border-b\n          border-l border-solid border-dokBlue-ultra\n          cursor-pointer\n        ",class:_vm.page !== 4
            ? 'bg-white text-dokBlue-ultra'
            : 'bg-dokBlue-ultra text-white',on:{"click":function($event){_vm.page = 4}}},[_vm._v(" Note confidentielle ")]):_vm._e(),_c('li',{staticClass:"\n          py-3\n          px-5\n          font-EffraR\n          w-full\n          text-center\n          border border-solid border-dokBlue-ultra\n          cursor-pointer\n          rounded-r-none\n          xl:rounded-r-lg\n        ",class:_vm.page !== 3
            ? 'bg-white text-dokBlue-ultra'
            : 'bg-dokBlue-ultra text-white',on:{"click":function($event){_vm.page = 3}}},[_vm._v(" Documents partagés ")])])]),(!_vm.noSubscribed)?_c('div',{staticClass:"bg-white border border-solid border-gray-300"},[(_vm.page === 2)?_c('consultations'):_vm._e(),(_vm.page === 3)?_c('documents'):_vm._e(),(_vm.page === 1 && _vm.authorized && _vm.user)?_c('healtBook',{attrs:{"patient":_vm.user}}):_vm._e(),(_vm.page === 4)?_c('div',{staticClass:"px-5 py-3 flex flex-col gap-y-3"},[_c('speach-textarea',{attrs:{"content":_vm.noteConfidentille},on:{"update:content":function($event){_vm.noteConfidentille=$event}}}),_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"flex-1 font-EffraR text-base"},[_vm._v(" Cette note est uniquement visible par le praticien qui l'a rédigé. ")]),_c('dok-button',{attrs:{"size":"md"},nativeOn:{"click":function($event){return _vm.onSaveNoteConfidentille.apply(null, arguments)}}},[_vm._v(" Sauvegarder ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.noSubscribed)?_c('div',{staticClass:"\n      w-full\n      lg:w-11/96 lg:mx-auto\n      bg-white\n      rounded-none\n      lg:rounded-lg\n      p-5\n      flex flex-col\n      gap-y-8\n    "},[_c('div',{staticClass:"mx-auto px-5 font-black text-3xl"},[_vm._v(" "+_vm._s(_vm.noSubscribedMessage)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }