<template>
  <div class="my-5 px-2 flex flex-col gap-y-3">
    <div
      v-if="user"
      class="
        bg-white
        py-2
        px-2
        flex
        items-center
        rounded-cu
        shadow
        border border-solid border-gray-200
      "
    >
      <router-link
        to="/patients"
        class="text-lg text-gray-800 hover:underline font-EffraL"
        >Patientèle</router-link
      >
      <i class="fas fa-angle-double-right fa-sm mx-2"></i>
      <span class="text-lg text-gray-800 font-EffraR">{{
        `${user.firstName} ${user.lastName}`
      }}</span>
    </div>

    <header
      class="border-2 border-solid border-gray-300 rounded-cu bg-white p-5"
    >
      <UserInformation :user="user" />
    </header>

    <nav>
      <ul class="flex flex-col xl:flex-row">
        <li
          class="
            py-3
            px-5
            font-EffraR
            w-full
            text-center
            border-0 border-t border-r
            xl:border-r-0 xl:border-b
            border-l border-solid border-dokBlue-ultra
            cursor-pointer
            rounded-l-none
            xl:rounded-l-lg
          "
          :class="
            page !== 1
              ? 'bg-white text-dokBlue-ultra'
              : 'bg-dokBlue-ultra text-white'
          "
          @click="page = 1"
          v-if="authorized"
        >
          Carnet de santé
        </li>
        <li
          class="
            py-3
            px-5
            font-EffraR
            w-full
            text-center
            border-0 border-t border-r
            xl:border-r-0 xl:border-b
            border-l border-solid border-dokBlue-ultra
            cursor-pointer
          "
          :class="
            page !== 2
              ? 'bg-white text-dokBlue-ultra'
              : 'bg-dokBlue-ultra text-white'
          "
          @click="page = 2"
        >
          Consultations
        </li>
        <li
          class="
            py-3
            px-5
            font-EffraR
            w-full
            text-center
            border-0 border-t border-r
            xl:border-r-0 xl:border-b
            border-l border-solid border-dokBlue-ultra
            cursor-pointer
          "
          :class="
            page !== 4
              ? 'bg-white text-dokBlue-ultra'
              : 'bg-dokBlue-ultra text-white'
          "
          @click="page = 4"
          v-if="authorized"
        >
          Note confidentielle
        </li>
        <li
          class="
            py-3
            px-5
            font-EffraR
            w-full
            text-center
            border border-solid border-dokBlue-ultra
            cursor-pointer
            rounded-r-none
            xl:rounded-r-lg
          "
          :class="
            page !== 3
              ? 'bg-white text-dokBlue-ultra'
              : 'bg-dokBlue-ultra text-white'
          "
          @click="page = 3"
        >
          Documents partagés
        </li>
      </ul>
    </nav>
    <div
      v-if="!noSubscribed"
      class="bg-white border border-solid border-gray-300"
    >
      <consultations v-if="page === 2"></consultations>
      <documents v-if="page === 3"></documents>
      <healtBook
        v-if="page === 1 && authorized && user"
        :patient="user"
      ></healtBook>
      <div v-if="page === 4" class="px-5 py-3 flex flex-col gap-y-3">
        <speach-textarea :content.sync="noteConfidentille"></speach-textarea>
        <div class="flex justify-between items-center w-full">
          <div class="flex-1 font-EffraR text-base">
            Cette note est uniquement visible par le praticien qui l'a rédigé.
          </div>
          <dok-button @click.native="onSaveNoteConfidentille" size="md">
            Sauvegarder
          </dok-button>
        </div>
      </div>
    </div>
    <div
      v-if="noSubscribed"
      class="
        w-full
        lg:w-11/96 lg:mx-auto
        bg-white
        rounded-none
        lg:rounded-lg
        p-5
        flex flex-col
        gap-y-8
      "
    >
      <div class="mx-auto px-5 font-black text-3xl">
        {{ noSubscribedMessage }}
      </div>
    </div>
  </div>
</template>

<script>
const Consultations = () => import("./components/Consultations");
const Documents = () => import("./components/Documents");
const UserInformation = () => import("./components/UserInformation");
const healtBook = () => import("@/views/health_book/index");
import { mapActions } from "vuex";
import moment from "moment";
const speachTextarea = () =>
  import("@/views/global-components/spechToTextarea");

export default {
  data() {
    return {
      page: 2,
      user: {},
      isLoading: true,
      documents: { other: [], prescriptions: [], resulta: [] },
      validation: { disbled: true, code: null },
      authorized: true,
      noteConfidentille: "",
      noSubscribed: false,
      noSubscribedMessage:
        "Vous ne pouvez plus accéder à cette rubrique, Merci de renouveler votre abonnement",
    };
  },
  created() {
    this.noSubscribed = this.$auth.isNotSubscribed();
  },
  async mounted() {
    this.user = this.$auth.getUserInfo();

    if (this.user.role == "TEAM-MEMBER") {
      this.authorized = false;
    }
    this.isLoading = true;
    this.$vs.loading({ color: "#000" });

    await this.$store.dispatch("user/GET_BY_ID", {
      onData: this.onData,
      id: this.$route.params.id,
    });

    await this.$store.dispatch("file/GET_BY_CLIENT", {
      onData: this.onPrescriptionsDocuments,
      params: {
        user: this.$route.params.id,
      },
    });

    await this.$store.dispatch("file/GET_BY_CLIENT", {
      onData: this.onResultaDocuments,
      params: {
        user: this.$route.params.id,
      },
    });

    await this.getNoteConfidentielle();

    if (this.$route.query.nav) {
      if (this.$route.query.nav === "carnet") this.page = 1;
    }
  },
  methods: {
    ...mapActions("authorization", [
      "REQUEST_AUTHORIZATION",
      "VALIDATE_AUTHORIZATION",
    ]),
    ...mapActions("dmp", [
      "GET_NOTE_CONFIDENTIELLE_PATIENT",
      "SAVE_NOTE_CONFIDENTIELLE_PATIENT",
    ]),
    async getNoteConfidentielle() {
      if (!this.user._id) return alert("Patient does not note exist");
      this.GET_NOTE_CONFIDENTIELLE_PATIENT({
        patientId: this.user._id,
        onData: ({ ok, data }) => {
          //console.log(data);
          if (ok) {
            this.noteConfidentille = data.note ? data.note : "";
          }
        },
      });
    },
    async onSaveNoteConfidentille() {
      this.$vs.loading({ color: "#000" });

      this.SAVE_NOTE_CONFIDENTIELLE_PATIENT({
        patientId: this.user._id,
        note: this.noteConfidentille,
        onData: ({ ok, message }) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 4000,
            text: ok
              ? "Mis à jour avec succès"
              : "Il y a un problème avec le traitement",
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check",
          });
        },
      });
    },
    setAuthorized: function (value) {
      this.authorized = value;
    },
    onSentSMS: async function () {
      let self = this;
      const data = await this.REQUEST_AUTHORIZATION({
        patientId: this.$route.params.id,
        authScope: "DMP",
        onData: function ({ ok, data, message }) {
          if (ok) {
            self.$vs.notify({
              color: "green",
              text: message,
            });
            self.validation.disbled = false;
          }
          if (!ok)
            self.$vs.notify({
              color: "red",
              text: message,
            });
        },
      });
    },
    onValidationSMS: async function () {
      let self = this;
      this.$vs.loading();
      const data = await this.VALIDATE_AUTHORIZATION({
        patientId: this.$route.params.id,
        authScope: "DMP",
        token: this.validation.code,
        onData: function ({ ok, data, message }) {
          let inputToken = document.getElementById("validationCode");
          if (!ok) {
            inputToken.classList.add("border-red-500");
            self.$vs.notify({
              color: "red",
              // title: "Erreur",
              text: message,
            });
          }
          if (ok) {
            self.authorized = true;
            inputToken.classList.remove("border-red-500");
            // self.getConsultation();
            self.$vs.notify({
              color: "green",
              // title: "Success",
              text: message,
            });
          }
          self.$vs.loading.close();
        },
      });
    },
    onResultaDocuments({ ok, data }) {
      if (ok) {
        this.documents.resulta = data;
      }
    },
    onPrescriptionsDocuments({ ok, data }) {
      if (ok) {
        this.documents.prescriptions = data;
      }
    },
    onData({ ok, data }) {
      if (!ok) {
        this.$router.push({ name: "listPatients" });
      }

      if (ok) {
        this.user = data;
      }
      this.$vs.loading.close();
    },
    moment() {
      return moment;
    },
  },
  components: {
    Consultations,
    Documents,
    healtBook,
    UserInformation,
    speachTextarea,
  },
};
</script>

<style lang="scss" scoped>
.card-nav > li {
  font-size: 18px;
  margin-right: 5px;
  span {
    font-size: 15px;
  }
}
</style>
